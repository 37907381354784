/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/swiper@5.4.2/js/swiper.min.js
 * - /npm/@fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.js
 * - /npm/jquery-waypoints@2.0.5/waypoints.min.js
 * - /npm/tooltipster@4.2.7/dist/js/tooltipster.bundle.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
